<template>
  <defaultSec :title="'微信退款列表：微信'">
    <keep-alive>
      <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
        <el-form-item label="退款单号">
          <el-input v-model="formParam.refundNo" placeholder="请输入退款单号" clearable></el-input>
        </el-form-item>
        <el-form-item label="子单号">
          <el-input v-model="formParam.orderNo" placeholder="请输入子单号" clearable></el-input>
        </el-form-item>
        <el-form-item label="退款状态">
          <el-select v-model="formParam.refundStatus" placeholder="请选择">
            <el-option label="全部" value=""></el-option>
            <el-option v-for="item in refundStatus" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="initList">查询</el-button>
<!--          <el-button type="danger" @click="refundMethod">退款</el-button>-->
        </el-form-item>
          <el-form-item>
              <el-switch
                  v-model="formParam.mine"
                  active-value="0"
                  active-text="全部"
                  inactive-value="1"
                  inactive-text="我的"
                  @change="initList">
              </el-switch>
          </el-form-item>
          <el-form-item>
              <el-button type="primary" @click="showBatchRefund">批量直接退款</el-button>
              <!--          <el-button type="danger" @click="refundMethod">退款</el-button>-->
          </el-form-item>
      </el-form>
    </keep-alive>
    <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
      <el-table :data="tableList" border size="mini" v-loading="loading" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading">
        <el-table-column type="index" label="序号"></el-table-column>
        <el-table-column label="退款单号" width="160">
          <template slot-scope="scope">
            <div style="font-size: 10px;">
              {{scope.row.refundNo}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单单号" width="240">
          <template slot-scope="scope">
            <div style="font-size: 10px;font-weight: 600;margin-bottom: 10px"> 子单：{{scope.row.orderNo}} <i style="color: #55a532;cursor: pointer" @click="copyOrderNo(scope.row.orderNo)" class="el-icon-document-copy"></i></div>
            <div style="font-size: 10px;">
              主单: {{scope.row.orderMainNo}}
            </div>
          </template>
        </el-table-column>
        <el-table-column label="订单信息" width="180">
          <template slot-scope="scope">
            <el-link @click="toBuyer(scope.row.buyerId)" style="font-size: 10px"><span style="color: #f6bbbb;font-size: 10px">● </span>客户：{{scope.row.buyerNickname||'-'}}</el-link>
            <div><span style="color: #f6bbbb;font-size: 10px">● </span>收货人：{{scope.row.consigneeName||'-'}}</div>
            <div><span style="color: #f6bbbb;font-size: 10px">● </span>电话：{{scope.row.consigneeMobile||'-'}}</div>
            <div><span style="color: #f6bbbb;font-size: 10px">● </span>下单: {{ formatDate(scope.row.orderCreateTime) }}</div>
            <div v-if="scope.row.pickupTime"><span style="color: #f6bbbb;font-size: 10px">● </span>提货: {{ formatDate(scope.row.pickupTime) ||'-'}}</div>
            <div><span style="color: #f6bbbb;font-size: 10px">● </span>订单：<el-tag size="mini" effect="plain" :type="orderStatusState[scope.row.orderStatus].type">{{orderStatusState[scope.row.orderStatus].name}}</el-tag></div>
          </template>
        </el-table-column>
        <el-table-column label="售后类型" width="90">
          <template slot-scope="scope">
            <span style="color: #209e91" v-if="scope.row.refundType==1">仅退款</span>
            <span style="color: #e85656" v-if="scope.row.refundType==2">退货退款</span>
          </template>
        </el-table-column>
        <el-table-column label="售后申请" min-width="180">
          <template slot-scope="scope">
            <div><span style="color: #55A532;font-size: 10px">● </span>申请：{{formatDate(scope.row.createTime)||'-'}}</div>
            <div><span style="color: #55A532;font-size: 10px">● </span>原因：{{scope.row.refundReason || '-'}}</div>
            <div><span style="color: #55A532;font-size: 10px">● </span>描述：{{scope.row.remark || '-'}}</div>
            <div><span style="color: #55A532;font-size: 10px">● </span>图片：
              <div>
                <template v-for="item in scope.row.picUrl">
                  <el-image
                      style="width: 50px; height: 50px;margin-right: 5px"
                      :src="item.picUrl"
                      :preview-src-list="[item.picUrl]">
                  </el-image>
                </template>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="退款金额(微信+余额)">
          <template slot-scope="scope">
            <div><span>申请: </span>{{ scope.row.refundAmountApply }}</div>
            <div><span>审批: </span>{{ scope.row.refundAmountCheck || '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="退款状态">
          <template slot-scope="scope">
              <el-tag size="mini" effect="dark" :type="refundStatus[scope.row.refundStatus-1].type">{{refundStatus[scope.row.refundStatus-1].name||''}}</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="审批" width="180">
          <template slot-scope="scope">
            <template v-if="scope.row.updateId">
              <div>审批人: {{scope.row.updateId}}</div>
              <div>时间：{{formatDate(scope.row.updateTime)}}</div>
            </template>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="100" fixed="right">
          <template slot-scope="scope">
              <el-button type="danger" v-if="scope.row.dataPlatform == 'wx_mall' && scope.row.refundStatus==1" plain size="mini" @click="toCheck(scope.row.refundNo,scope.row.orderNo,3,scope.row.orderSource)">审批</el-button>
              <el-button type="primary" v-if="scope.row.dataPlatform == 'wx_mall' && scope.row.refundStatus!==1" plain size="mini" @click="toCheck(scope.row.refundNo,scope.row.orderNo,5,scope.row.orderSource)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
        <!-- 批量直接退款 -->
        <el-dialog title="批量直接退款" :visible.sync="batchRefundOrderShow" :before-close="handleClose">
            <el-form ref="form" :model="formBatchRefundOrder" label-width="80px" class="draw-box">
                <el-form-item label="订单号" required>
                    <el-input type="textarea" placeholder="多个订单回车隔开" v-model="formBatchRefundOrder.orderNos" size="mini"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="batchRefundOrder">确定</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </tablePagination>
  </defaultSec>
</template>

<script>
import defaultSec from '@c/defaultSection'
import tablePagination from '@c/tablePagination'
export default {
  data() {
    return {
      loading: false,
      formParam:{
        pageSize:15,
        currentPage:1,
        refundNo:'',
        orderNo:'',
        refundStatus:'',
        dataPlatform:'',
        pageType:2,
          mine:"0"
      },
      tableList:[],
      total:0,
      orderStatusState: {
        0: {name: "已取消", type: 'info'},
        5: {name: "待支付", type: 'danger'},
        10: {name: "已支付", type: 'success'},
        15: {name: "部分配货", type: 'success'},
        20: {name: "全部配货", type: 'success'},
        25: {name: "部分发货", type: 'success'},
        30: {name: "已发货", type: 'success'},
        35: {name: "已送达", type: 'success'},
        40: {name: "交易完成", type: 'primary'},
        45: {name: "交易关闭", type: 'warning'},
      },
      refundStatus: [
        {name: "已申请", type: 'primary',value:1},
        {name: "已审核", type: 'warning',value: 2},
        {name: "已拒绝", type: 'danger',value: 3},
        {name: "已完成", type: 'success',value: 4},
        {name: "已取消", type: 'info',value: 5},
      ],
        batchRefundOrderShow: false,
        formBatchRefundOrder: {
          orderNos: ""
        }
    }
  },
  components:{
    defaultSec,tablePagination
  },
  watch:{
    '$route'(to,from){
      //监听路由变化
      if(to.name!=from.name){
        this.initList()
      }
    }
  },
  mounted(){
    this.initList()
  },
  methods: {
    initList() {
      this.loading = true;
      this.$apiGet('lechun-csms/refundApi/listRefundInfoPageForWx',this.formParam).then(res => {
        console.log(res);
        this.loading = false;
        this.total = res.total;
        this.tableList = res.list

      })
    },
    refundMethod(){
      this.$apiGet('lechun-csms/refundApi/refundPay',this.formParam).then(res => {
        console.log(res)
      })
    },
    handlePage(page){
      this.formParam.currentPage = page;
      this.initList()
    },
    //用户详情
    toBuyer(id) {
      let url = window.location.origin+'/userDetail/'+id
      window.open(url,'_blank')
    },
    // 审核退款
    toCheck(refundNo,orderNo,pageType,orderSource){
      this.$router.push({name:'refundsDetailForWXMall',params:{refundNo:refundNo,orderNo:orderNo,pageType:pageType,fromPage:'refundsForWXMall',orderSource:orderSource}})
    },

    formatRefundType:function(row, column){
      if(row.refundType == 1){
        return  "退款";
      }
      if(row.refundType == 2){
        return  "退货退款";
      }
    },
    //复制单号
    copyOrderNo(text){
      var input = document.createElement("textarea"); // 创建input对象
      input.value = text; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.$message({
        type: 'success',
        message: '已复制到剪贴板'
      });
    },
    formatDate(datetime) {
      if (datetime == null || datetime == '') {
        return;
      }
      var date = new Date(datetime).Format('yyyy-MM-dd hh:mm:ss');
      return date;
    },
      batchRefundOrder(){
          this.$confirm('确定直接退款吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
          }).then(() => {
              this.$apiGet('lechun-csms/refundApi/refundDirectBatch', this.formBatchRefundOrder).then(res => {
                  console.log("resres",res);
                  this.$message({
                      message: res,
                      type: 'success'
                  });
              });
          }).catch(() => {
              this.$message({
                  type: 'info',
                  message: '取消操作'
              });
          });
      },
      showBatchRefund(){
          this.batchRefundOrderShow = true;
      }
  }
}
</script>


<style scoped>
.expand-title{
  width: 70px;
  color: #444548;
  line-height: 30px;
  text-align: right;
  margin-right: 10px;
  font-weight: 600;
}
.expand-flex{
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
</style>